import { defineStore } from "pinia";
import listaCompletaAlertas from "@/helpers/lista_alertas_superbox.json";

export const storeAlertasSuperBox = defineStore("sessionAlertasSB", {
  state: () => ({
    alertas_altos: listaCompletaAlertas.alerta_alta.map((alerta) => ({
      ...alerta,
      valor: false,
    })),
    alertas_baixos: listaCompletaAlertas.alerta_baixa.map((alerta) => ({
      ...alerta,
      valor: false,
    })),
    alertas_positivos: listaCompletaAlertas.alerta_positivo.map((alerta) => ({
      ...alerta,
      valor: false,
    })),

    eventosAlertas: {},
    updateTrigger: 0,

    dicionarios: {
      "Dados-Cadastrais": ["faixa_etaria_inferior", "faixa_etaria_elevada"],

      "Renda-presumida": [
        "jovem_renda_presumida_elevada",
        "renda_presumida_elevada",
      ],

      "BlocoPFAssociacoesPessoais-1": [
        "perfil_servidor_militar",
        "associacao_beneficios",
        "associacao_servidores",
      ],

      "BlocoPFAssociacoesPessoais-2": ["perfil_pep"],

      "Geolocalidades-principal": [
        "cep_restrito",
        "cidade_dormitorio_afastada",
        "proximidade_fronteira",
      ],

      "Geolocalidades-indices": [
        "score_localidade_baixo",
        "score_localidade_elevado",
      ],

      "Geolocalidades-enderecos": ["cep_dentro_eds", "numero_eaps_elevado"],

      "Passagens-tab": ["passagem_recente", "passagens_seguradora"],

      "Mapa-riscos": ["score_sonar_baixo", "score_sonar_elevado"],

      "Empresas-tb1": [
        "perfil_empresario",
        "associacao_cnpj_atipico_faixa_etaria",
        "tempo_fundacao_elevado",
        "montante_total_capital_social_elevado",
        "geo_renda_incompativel_perfil_empresas",
      ],

      "Empresas-tb5": [
        "historico_recente_empresa_encerrada",
        "nao_baixadas_falencia_recuperacao",
        "excesso_empresas_encerradas",
      ],

      "Empresas-tb7": ["encerradas_falencia_recuperacao"],

      "Empresas-ranking": [
        "socio_pj_situacao_cadastral_especial",
        "ongs_fundacoes_partidos",
        "vinculo_societario_recente",
        "empresa_fundacao_recente",
        "empresa_ativa_exterior",
        "top5_empresas_fora_uf_cpf",
        "cpf_administrador_de_cnpj",
        "alteracao_recente_qsa",
        "qsa_vinculo_parental",
        "quadros_societarios_estaveis",
        "extensao_regional_nacional",
        "polo_atuacao_cnae",
        "vinculo_holding_familiar",
        "atipicidade_capital_social",
      ],
    },
  }),

  getters: {
    listaAlertas: (state) => (tipo) => {
      if (tipo == "alta") {
        return state.alertas_altos;
      }
      if (tipo == "baixa") {
        return state.alertas_baixos;
      }
      if (tipo == "positivo") {
        return state.alertas_positivos;
      } else {
        return [];
      }
    },
  },
  actions: {
    ativaAlerta(idRecebido) {
      for (const alerta of this.alertas_altos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
      for (const alerta of this.alertas_baixos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
      for (const alerta of this.alertas_positivos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
    },

    resetAlertasPf() {
      for (const alerta of this.alertas_altos) {
        alerta.valor = false;
      }
      for (const alerta of this.alertas_baixos) {
        alerta.valor = false;
      }
      for (const alerta of this.alertas_positivos) {
        alerta.valor = false;
      }
    },

    // ativarEvento(chave) {
    // this.eventosAlertas[chave] = true;
    // this.updateTrigger++;
    // },

    // ativarEvento(valor) {
    //   for (const [chave, valores] of Object.entries(this.dicionarios)) {
    //     if (valores.includes(valor)) {
    //       const div = document.getElementById(chave);
    //       div.scrollIntoView({ behavior: "smooth" });
    //     }
    //   }
    // },

    async ativarEvento(valor) {
      for (const [chave, valores] of Object.entries(this.dicionarios)) {
        if (valores.includes(valor)) {
          // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
          const div = document.getElementById(chave);
          if (div) {
            div.scrollIntoView({ behavior: "smooth" });
            // await delay(1000);

            // window.scrollBy({
            //   top: -100,
            //   behavior: "smooth",
            // });
          }
        }
      }
    },
  },
});
