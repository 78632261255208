import { defineStore } from "pinia";
import { apiService } from "../services";
import { authHeader } from "../helpers";
import { userService } from "@/services";

export const userPreferenceStore = defineStore("userPreference", {
  state: () => {
    return {
      produtosPreferidos: [],
      modelosPreferidos: [],
      modelosConsultasAddFavoritos: [],
      consultaAdicional: false,
      exibirFavoritos: false,
      ocultarDados: false,
      exibirTootipsConsultas: true,
      tipoDownloadConsulta: "",
      parametrosSugeridos: {},
      usuarioLogado: {},
      termosUsoAssistente: false,
    };
  },
  actions: {
    async recuperaUsuarioLogado() {
      await userService.recuperaDadosUsuarioLogado().then((usuario) => {
        this.usuarioLogado = usuario;
      });
    },

    async syncPreferencias() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_produtosFavoritos";
      const produtosFavoritos = await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          return resultado;
        });
      return produtosFavoritos;
    },

    createPreferencia(nomePreferencia, valor = "") {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          Nome: nomePreferencia,
          Valor: valor,
        }),
      };

      let url = apiService.enderecoApiConta() + "/conta/preferencia";

      return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          return resultado;
        });
    },

    substituiPreferencia(nomePreferencia, valorPreferencia) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          Nome: nomePreferencia,
          Valor: valorPreferencia,
        }),
      };

      let url = apiService.enderecoApiConta() + "/conta/preferencia";

      return fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          return resultado;
        });
    },

    async syncPreferenciaConsultaAdicional() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_consultaAdicional";
      return await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          if (resultado.Valor == null) {
            this.createPreferencia("PortalOutbox_consultaAdicional", "false");
            this.consultaAdicional = false;
            return this.consultaAdicional;
          }
          this.consultaAdicional = resultado.Valor == "true";
          return this.consultaAdicional;
        });
    },

    async syncPreferenciaGeral(nomePref) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=" +
        nomePref;
      const produtosFavoritos = await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          return resultado;
        });
      return produtosFavoritos;
    },

    async syncPreferenciaExibeParametros() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_ExibeParametros";
      return await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          if (resultado.Valor == null) {
            this.createPreferencia("PortalOutbox_ExibeParametros", "false");
            this.exibirFavoritos = false;
            return this.exibirFavoritos;
          }
          this.exibirFavoritos = resultado.Valor == "true";
          return this.exibirFavoritos;
        });
    },

    async syncPreferenciaOcultarDados() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_OcultarDados";
      return await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          if (resultado.Valor == null) {
            this.createPreferencia("PortalOutbox_OcultarDados", "false");
            this.ocultarDados = false;
            return this.ocultarDados;
          }
          this.ocultarDados = resultado.Valor == "true";
          return this.ocultarDados;
        });
    },

    async syncPreferenciaConsultasTooltips() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_consultasTooltips";
      return await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          if (resultado.Valor == null) {
            this.createPreferencia("PortalOutbox_consultasTooltips", "true");
            this.exibirTootipsConsultas = true;
            return this.exibirTootipsConsultas;
          }
          this.exibirTootipsConsultas = resultado.Valor == "true";
          return this.exibirTootipsConsultas;
        });
    },

    async syncPreferenciaTipoDownload() {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      let url =
        apiService.enderecoApiConta() +
        "/conta/preferencia" +
        "?nomePreferencia=PortalOutbox_TipoDownload";
      return await fetch(url, requestOptions)
        .then(apiService.handleResponse)
        .then((resultado) => {
          if (resultado.Valor == null) {
            this.createPreferencia("PortalOutbox_TipoDownload", "pdf");
            this.tipoDownloadConsulta = "pdf";
            return this.tipoDownloadConsulta;
          }
          this.tipoDownloadConsulta = resultado.Valor;
          return this.tipoDownloadConsulta;
        });
    },
  },
});
