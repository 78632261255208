import { defineStore } from "pinia";
import listaCompletaAlertas from "@/helpers/lista_alertas_pj.json";

export const storeAlertasSuperBoxPJ = defineStore("sessionAlertasSBPJ", {
  state: () => ({
    alertas_altos: listaCompletaAlertas.alerta_alta.map((alerta) => ({
      ...alerta,
      valor: false,
    })),
    alertas_baixos: listaCompletaAlertas.alerta_baixa.map((alerta) => ({
      ...alerta,
      valor: false,
    })),
    alertas_positivos: listaCompletaAlertas.alerta_positivo.map((alerta) => ({
      ...alerta,
      valor: false,
    })),

    eventosAlertas: {},
    updateTrigger: 0,
  }),

  getters: {
    listaAlertas: (state) => (tipo) => {
      if (tipo == "alta") {
        return state.alertas_altos;
      }
      if (tipo == "baixa") {
        return state.alertas_baixos;
      }
      if (tipo == "positivo") {
        return state.alertas_positivos;
      } else {
        return [];
      }
    },
  },
  actions: {
    ativaAlerta(idRecebido) {
      for (const alerta of this.alertas_altos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
      for (const alerta of this.alertas_baixos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
      for (const alerta of this.alertas_positivos) {
        if (alerta.idAlerta === idRecebido) {
          alerta.valor = true;
        }
      }
    },

    resetAlertasPj() {
      for (const alerta of this.alertas_altos) {
        alerta.valor = false;
      }
      for (const alerta of this.alertas_baixos) {
        alerta.valor = false;
      }
      for (const alerta of this.alertas_positivos) {
        alerta.valor = false;
      }
    },

    async ativarEvento(valor) {
      for (const [chave, valores] of Object.entries(this.dicionarios)) {
        if (valores.includes(valor)) {
          // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
          const div = document.getElementById(chave);
          if (div) {
            div.scrollIntoView({ behavior: "smooth" });
            // await delay(1000);

            // window.scrollBy({
            //   top: -100,
            //   behavior: "smooth",
            // });
          }
        }
      }
    },
  },
});
