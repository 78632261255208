import { defineStore } from "pinia";
import jsonSuperBox from "@/helpers/produtos_superbox.json";

export const produtoSuperBox = defineStore('sessionAlertasSB', {
    state: () => ({
        produtoSuperBox: jsonSuperBox.superBox,
        listaProdutosSuperBox: jsonSuperBox.produtosSuperBox
        
    }),
    
    getters: {
        // listaAlertas: (state) => (tipo) => {      
        //     if (tipo == "alta") {
        //         return state.alertas_altos;
        //     }
        //     if (tipo == "baixa") {
        //         return state.alertas_baixos;
        //     }
        //     if (tipo == "positivo") {
        //         return state.alertas_positivos;
        //     } else {
        //         return [];
        //     }
        // },
    },
    actions: {
       
    }
})
