import { defineStore } from "pinia";

export const blocosSuperBoxStore = defineStore('sessionBlocosSB', {
    state: () => ({
        openBlocos: new Array(9).fill(false),
    }),
    
    actions: {
        alteraBloco(bloco, id) {
            this.openBlocos[id] = bloco
        },
        
        abreTodosBlocos() {
            for (let i = 0; i < 10; i++) {
                this.openBlocos[i] = true;
            }
        },
        
        // fechaTodosBlocos() {
        //     for (let i = 0; i <= 9; i++) {
        //         this.openBlocos[i] = false;
        //     }
        // },
    },
})
